import { React, useState, useEffect } from "react";
import { Box, Paper, ThemeProvider } from "@mui/material";
import Header from "./Components/Layout";
import axios from "axios";
import { light, dark } from "./Theme";
import './App.css';

function App() {

  const [order, set_order] = useState(
    JSON.parse(localStorage.getItem("order")) ?? { products: [], total: 0 }
  );

  const [data, set_data] = useState(
    {
      "id": 119,
      "name": "Soupe",
      "description": null,
      "products": [
        {
          "id": 308,
          "name": "Soupe saumon",
          "price": 50,
          "image": "products/March2023/beeZ7oyRB4Zo4ohnqNqy.jpg",
          "designation": "Saumon tempura, udon, poireau, wakame",
          "category_id": 119
        },
        {
          "id": 309,
          "name": "Soupe fruit de mer",
          "price": 45,
          "image": "products/March2023/l6KUaqfXfxFtoRS5hSlB.jpg",
          "designation": "Crevette, moules, palourdes, couteaux, St-jacque wakame, poireau, brocoli, carotte",
          "category_id": 119
        },
        {
          "id": 310,
          "name": "Soupe poulet",
          "price": 50,
          "image": "products/March2023/sDwcYRKCB0h8f6dwHRP1.jpg",
          "designation": "Boulette de poulet, champignon, brocoli, asperge, carotte, udon, wakame",
          "category_id": 119
        },
        {
          "id": 311,
          "name": "Soupe miso palourdes",
          "price": 50,
          "image": "products/March2023/IW0t39QDXOXPZ8hucV0j.jpg",
          "designation": "Palourdes, carottes,  poireau, Wakame",
          "category_id": 119
        },
        {
          "id": 312,
          "name": "Soupe Miso",
          "price": 30,
          "image": "products/March2023/mkbMYPX3qxucoQfWPpL7.jpg",
          "designation": "Tofu, poireau, wakame",
          "category_id": 119
        }
      ]
    },
    {
      "id": 100,
      "name": "Accompagnements",
      "description": null,
      "products": [
        {
          "id": 100,
          "name": "Goma wakamé",
          "price": 45,
          "image": "products/March2023/cVWcvSD4nnlxLqDr2eaw.jpg",
          "designation": null,
          "category_id": 100
        },
        {
          "id": 101,
          "name": "Salade de choux",
          "price": 30,
          "image": "products/March2023/uKnpRS72HyEdisc8OkkA.jpg",
          "designation": null,
          "category_id": 100
        },
        {
          "id": 102,
          "name": "Riz nature",
          "price": 20,
          "image": "products/March2023/5tEjeZ1UwcLWAO49gQLG.jpg",
          "designation": null,
          "category_id": 100
        },
        {
          "id": 103,
          "name": "Riz Sauté",
          "price": 35,
          "image": "products/March2023/u2szQtT7cVafbyiuAgf0.jpg",
          "designation": null,
          "category_id": 100
        },
        {
          "id": 104,
          "name": "Edamame",
          "price": 35,
          "image": "products/March2023/mBnTBZbdDOl2NND3falI.jpg",
          "designation": "Edamame au sel marin",
          "category_id": 100
        },
        {
          "id": 105,
          "name": "Spicy Edamame",
          "price": 40,
          "image": "products/March2023/2fEYOjDEmlbH0NNXpsyQ.jpg",
          "designation": null,
          "category_id": 100
        },
        {
          "id": 426,
          "name": "Riz vinaigré",
          "price": 25,
          "image": "products/March2023/px2ScVpSqBkQKfW8tRAQ.jpg",
          "designation": null,
          "category_id": 100
        }
      ]
    },
    {
      "id": 121,
      "name": "Starters",
      "description": null,
      "products": [
        {
          "id": 334,
          "name": "Salade poulpe",
          "price": 60,
          "image": "products/March2023/KKdbp5uRiYQ7bahFwDS2.jpg",
          "designation": "Poulpe, concombre, wakame",
          "category_id": 121
        },
        {
          "id": 336,
          "name": "Gambas wasabi",
          "price": 80,
          "image": "products/March2023/ucBKssI8LY9CCS7zfOay.jpg",
          "designation": "crevette tempura, sauce wasabi",
          "category_id": 121
        },
        {
          "id": 337,
          "name": "Crevette Sauvage",
          "price": 80,
          "image": "products/March2023/Qrw4tP3Pk4kNvuK6bLWj.jpg",
          "designation": "Sauce miso epicé",
          "category_id": 121
        },
        {
          "id": 339,
          "name": "Croustillant Gambas",
          "price": 85,
          "image": "products/March2023/VX8p2T6cnJ1Q7SmkqtPJ.jpg",
          "designation": "Gambas, anguille, crabe, sauce garlic",
          "category_id": 121
        },
        {
          "id": 340,
          "name": "Gambas Kadaïf",
          "price": 80,
          "image": "products/March2023/Ja84wK1aaBl3naYsoH4I.jpg",
          "designation": "Emulsion au curry",
          "category_id": 121
        },
        {
          "id": 342,
          "name": "Ceviche de Daurade",
          "price": 85,
          "image": "products/March2023/SK2Sn9tFthrO7UI93NEn.jpg",
          "designation": "Daurade, concombre, radi, tomate cerise, mangue, sauce ceviche, ikura",
          "category_id": 121
        },
        {
          "id": 343,
          "name": "Aubergine miso",
          "price": 60,
          "image": "products/March2023/5ZrnrUwoQDOfRFffZjzU.jpg",
          "designation": "Aubergine marinée au miso",
          "category_id": 121
        },
        {
          "id": 344,
          "name": "Croquettes crabe poisson blanc",
          "price": 70,
          "image": "products/March2023/PRsbcjWPDnD2kB3QyQwG.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 345,
          "name": "Crevette Panée",
          "price": 50,
          "image": "products/March2023/mZrOA5KjCxGIl5jmueGn.jpg",
          "designation": "Sauce Kamikaze",
          "category_id": 121
        },
        {
          "id": 346,
          "name": "Nems fruit de mer",
          "price": 55,
          "image": "products/March2023/CZEoMMmfQFVTIXj4fQfr.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 347,
          "name": "Gyoza Fuit de mer",
          "price": 70,
          "image": "products/March2023/kcbHyR4KXniqIXklDjPe.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 348,
          "name": "Vapeurs crabe",
          "price": 70,
          "image": "products/March2023/xsbZa9uAynZXXthHsz3m.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 349,
          "name": "Crevette panée Shiitake",
          "price": 70,
          "image": "products/March2023/7i9hpxHPn8oHg0SCvuj0.jpg",
          "designation": "Ciboulette, sauce aigre doux kamikaze",
          "category_id": 121
        },
        {
          "id": 350,
          "name": "Ebi Fry (x3)",
          "price": 50,
          "image": "products/March2023/nJMm3OvpjHQnK6m7lV4V.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 351,
          "name": "Boulette de saumon",
          "price": 45,
          "image": "products/March2023/dAKbabKXCg59R5HPJMCW.jpg",
          "designation": "Saumon pané, sauce aigre doux",
          "category_id": 121
        },
        {
          "id": 353,
          "name": "Poulet Caragué",
          "price": 40,
          "image": "products/March2023/6sXvnWud0sqM26o2BHmm.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 380,
          "name": "Nems légumes",
          "price": 35,
          "image": "products/March2023/brrtUlFE4ym3z9bDmmrr.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 381,
          "name": "Nems anguille",
          "price": 80,
          "image": "products/March2023/Rio9momYoQaeiXrVPdlc.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 382,
          "name": "Nems crevette thaï",
          "price": 60,
          "image": "products/March2023/E3uct23bDGGiitSTp1ts.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 383,
          "name": "Nems crevette",
          "price": 60,
          "image": "products/March2023/f5iwWiLAVATqYs4LnnKD.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 384,
          "name": "Nems poulet",
          "price": 40,
          "image": "products/March2023/dZOSTWQsAF4arBVXBUaX.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 385,
          "name": "Croquette Saumon",
          "price": 75,
          "image": "products/March2023/q5IVOtb4xJub5tgMuyx9.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 386,
          "name": "Croquette crevette",
          "price": 70,
          "image": "products/March2023/C8oaT49IHb6spYrRKmkV.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 387,
          "name": "Vapeurs crevette",
          "price": 65,
          "image": "products/March2023/eCsnGIXKSad4xywq1UXu.jpg",
          "designation": null,
          "category_id": 121
        },
        {
          "id": 388,
          "name": "Gyoza poulet",
          "price": 65,
          "image": "products/March2023/TpEA3zQ4anKHeHMZiE42.jpg",
          "designation": null,
          "category_id": 121
        }
      ]
    }
  );

  const [is_dark_mode, set_is_dark_mode] = useState(
    localStorage.getItem("is_dark_mode") == null
      ? true
      : localStorage.getItem("is_dark_mode") == "true"
  );

  useEffect(() => {
    // load_default_data()
    load_data();
    axios
      .get(`${process.env.REACT_APP_API_URL}company`)
      .then((res) => {
        if (res?.data?.result) localStorage.setItem('company', JSON.stringify(res?.data?.data))
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  const load_default_data = () => {
    axios
      .get("/data.json")
      .then((res) => set_data(res?.data))
      .catch((err) => console.log(err));
  };

  const load_data = () => {
    let result_api_true = false;
    axios
      .get(`${process.env.REACT_APP_API_URL}categories_with_products`)
      .then((res) => {
        set_data(res?.data);
        result_api_true = true;
      })
      .catch((err) => {
        load_default_data();
      })
      .finally(() => {
        if (result_api_true === false && data?.length == 0) load_default_data();
      });
  };

  return (
    <ThemeProvider theme={is_dark_mode ? dark : light}>
      <Header order={order} set_order={set_order} data={data} />
    </ThemeProvider>
  );
}

export default App;
