import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import theme from "../Theme";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import { motion } from "framer-motion";
import { MdAdd } from "react-icons/md";
import { Box, CircularProgress } from "@mui/material";
import ProductModal from "./Modal/ProductModal";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ProductList({ category, product, order, set_order }) {

  const [xValue, setxValue] = useState(0);
  const [yValue, setyValue] = useState(0);
  const is_sm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = async (p) => {
    const i = Object.values(order?.products).map(p1 => p1?.id).indexOf(p?.id)
    // console.log(i);
    if (Object.values(order.products).filter(p1 => p1?.id == p?.id).length == 0)
      await set_order({
        ...order,
        products: {
          ...order?.products,
          [order?.products?.length]: { ...p, qte: 1, category: category },
        },
      });
    else
      await set_order({
        ...order,
        products: {
          // ...Object.values(order.products).filter(p1 => p1?.id == p?.id)
          ...order?.products,
          [i]: { ...p, qte: order?.products[i]?.qte + 1, category: category },
        },
      });
    // setxValue(45);
    // setyValue(45);

    // console.log(cart_width, cart_height);

    // const x = ref_card?.current.offsetLeft;
    // const y = ref_card?.current.offsetTop;
    // set_motion_props({ opacity: 1, x: width-x-70, y: height-y-170, width: 30, height:0 }) // 70=Cart, 30=Product, 150=V-Header

    // const [motion, set_motion] = useState({ opacity: 1, x: xValue, y: yValue, width: 0, height: 0 });
    // console.log(width, height);
    // setxValue(width - 20);
    // setyValue(height - 20);
    set_motion_animation({ opacity: 0})
  };

  // const loadImage = () => {

  //   // const onUploadProgress = async (progressEvent) => {
  //   //   const { loaded, total } = progressEvent;
  //   //   let percent = Math.floor((loaded * 100) / total);
  //   //   if (percent < 100) {
  //   //     console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
  //   //   }
  //   // };

  //   // axios
  //   //   .get(process.env.REACT_APP_URL + "storage/" + product?.image, {}, {
  //   //     headers: {},
  //   //     // onUploadProgress,
  //   //   })
  //   //   .then(() => set_image(image));

  //   // console.log(product)

  //   // const image1 = new Image();
  //   // // image.crossOrigin = "Anonymous";
  //   // image1.src = process.env.REACT_APP_URL + "storage/" + product?.image;
  //   // image1.onload = () => {
  //   //   console.log('onload ', (new Date()).toISOString());
  //   // }
  //   // image1.onerror = () => {
  //   //   console.log('onerror ', (new Date()).toISOString())
  //   // }
  //   // set_image(image)

  //   const url = process.env.REACT_APP_URL + "api/image/" + product?.image
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       // let result = (res && res.data && res.data[0].file) || [];
  //       const base64 = btoa(
  //         new Uint8Array(res.data).reduce(
  //           (data, byte) => data + String.fromCharCode(byte),
  //           ''
  //         )
  //       )

  //       set_image(`data:;base64,${base64}`)
  //       // setImage(result[0]);
  //     })
  //     .catch((res) => {
  //       console.error('error: ', url)
  //     })
  //   // console.log(
  //   //   axios.post(process.env.REACT_APP_URL + "storage/" + product?.image)
  //   // )

  //   // const image = new Image()
  //   // image.src = process.env.REACT_APP_URL + "storage/" + product?.image
  //   // console.log(image)

  //   // image.src = process.env.REACT_APP_URL + "image/" + product?.image
  //   // image.src = process.env.REACT_APP_URL + "api/image/" + product?.image
  //   // image.onload = () => {
  //   //   console.log('onload ', (new Date()).toISOString());
  //   // const canvas = document.createElement('canvas');
  //   // const ctx = canvas.getContext('2d');
  //   // canvas.height = image.naturalHeight;
  //   // canvas.width = image.naturalWidth;
  //   // ctx.drawImage(image, 0, 0);
  //   // const dataUrl = canvas.toDataURL();
  //   // callback && callback(dataUrl)
  //   // }

  // };

  // useEffect(() => loadImage(), [])

  // const [motion_props, set_motion_props] = useState({});
  // const ref_card = useRef();
  const [open, setOpen] = useState(false);
  const [loading, set_loading] = useState(true);
  const [motion_animation, set_motion_animation] = useState({});

  return (
    <>
      <ProductModal
        open={open}
        image={process.env.REACT_APP_URL + "storage/" + product?.image}
        onClose={() => setOpen(false)}
        product={product}
        handleClick={handleClick}

        order={null}
        set_order={null}
        products={null}
        total={null}
      />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, motion_animation }}
        transition={{ duration: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card
          // onClick={() => handleClick(product)}
          sx={{
            borderRadius: 5,
            "&:hover": {
              cursor: "pointer",
              // transform: "scale(1.05)",
              transition: theme.transitions.create(
                ["background-color", "transform"],
                {
                  duration: theme.transitions.duration.standard,
                }
              ),
            },
          }}
        >
          <Box sx={{ display: 'flex', textAlign: "center", justifyContent: "center", alignItems: 'center' }}>
            {loading && <CircularProgress color="secondary" />}
            <CardMedia onClick={() => setOpen(true)} component="img" alt={product?.name}
              sx={{ height: loading ? 300 : 'auto', width: loading ? 0 : null, bgcolor: "primary.main" }}
              image={process.env.REACT_APP_URL + "storage/" + product?.image}
              onLoadCapture={() => set_loading(false)}
              onErrorCapture={() => set_loading(false)}
            />
            {/* <img src={image} alt="loading..."
              sx={{ maxHeight: loading ? 0 : 300, minHeight: loading ? 0 : 300, width: loading ? 0 : '100%', bgcolor: "primary.main" }}
            /> */}
          </Box>

          <CardContent sx={{ pt: 1, pb: 2 }}>
            <Typography color="text.secondary" sx={{ textAlign: "left", whiteSpace: "nowrap" }} fontSize={is_sm ? 15 : 20}>
              {product?.name}
            </Typography>
          </CardContent>

          <Box sx={{ display: "flex", px: 1 }}>
            <CardActions disableSpacing sx={{ width: "100%", justifyContent: "flex-start", whiteSpace: "nowrap" }}>
              <Typography color="text.secondary" fontSize={20}>
                {product?.price} {JSON.parse(localStorage.getItem("configs"))?.currency ?? 'DH'}
              </Typography>
            </CardActions>
            <CardActions disableSpacing sx={{ width: "100%", justifyContent: "flex-end" }}>
              <IconButton onClick={() => handleClick(product)} sx={{ color: 'secondary.main' }}>
                <MdAdd size={40} />
              </IconButton>
            </CardActions>
          </Box>
        </Card>
      </motion.div>
    </>
  );
}
