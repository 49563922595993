import * as React from "react";

import {
  Box,
  Popper,
  Fade,
  Paper,
  Badge,
  IconButton,
  Fab,
} from "@mui/material";
import { MdShoppingCart } from "react-icons/md";
import theme from "../Theme";
// import useContainerDimensions from "./useContainerDimensions";
import CheckoutModal from "./Modal/CheckoutModal";

export default function PositionedPopper({
  order,
  set_order,
  update,
  set_cart_width,
  set_cart_height,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const ref = React.useRef(null);
  // const { cart_width, cart_height } = useContainerDimensions(ref);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  return (
    <Box sx={{ width: 500 }}>
      <CheckoutModal
        order={order}
        set_order={set_order}
        open={open}
        onClose={() => setOpen(false)}
        products={order.products}
        total={order.total}
      />

      <Fab
        transition
        onClick={handleClick("top-start")}
        sx={{
          width: 70,
          height: 70,
          p: 5,
          position: "fixed",
          bottom: (theme) => theme.spacing(4),
          right: (theme) => theme.spacing(4),
          bgcolor: "primary.dark",
          "&:hover": {
            cursor: "pointer",
            transform: "scale(1.1)",
            bgcolor: "primary.dark",
          },
        }}
      >
        <Badge
          ref={ref}
          badgeContent={Object.values(order?.products)?.map(p => p?.qte ?? 0)?.reduce((a, b) => a + b, 0)}
          color="secondary"
          overlap="circular"
        >
          <MdShoppingCart size={50} color={theme?.palette?.secondary?.light} />
        </Badge>
      </Fab>
    </Box >
  );
}
