import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppBar } from "@mui/material";

export default function VerticalTabs(props) {
  const handleChange = (event, newValue) => {
    props?.setValue(newValue);
  };

  return (
    <AppBar position="sticky" sx={{ height: window.innerHeight }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={props?.value}
        // scrollButtons
        // color="text.secondary"
        onChange={handleChange}
        sx={{
          borderRight: 1, borderColor: "divider", backgroundImage: 'url(/background.png)', backgroundSize: 1500,
          "&:hover": { bgcolor: '#202020', color: '#2f2f2f', },
        }}
      >
        {props?.data?.length > 0 && props?.data?.map((t, i) => (
          <Tab key={i} label={t?.name} sx={{
            "&.Mui-selected": {
              color: 'secondary.light'
            },
            "&:hover": {
              // border: 3,
              // borderColor: 'secondary.main',
              bgcolor: 'secondary.dark',
              // opacity: 0.7
            },
          }} />
        ))}
      </Tabs>
    </AppBar >
  );
}
