import * as React from 'react';
import PropTypes from 'prop-types';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from 'axios';
import theme from "../../Theme";
import moment from 'moment'
import { n_format } from '../../Functions/n_format';
import {
  MdChecklist, MdCheck, MdShoppingCart, MdLabelImportant,
  MdDone, MdAdd, MdClose, MdRemove, MdOutlineHourglassEmpty
} from "react-icons/md";
import {
  Button, Grid, TextField, Typography, Paper, StepLabel, Stepper,
  Step, IconButton, Backdrop, Dialog, Autocomplete, Table,
  TableFooter, TableRow, TableCell, TableHead, TableBody, Avatar,
  CircularProgress, Snackbar, Toolbar, AppBar,
  Slide, useMediaQuery, Box, Stack
} from "@mui/material";

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <MdShoppingCart className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <MdShoppingCart size={25} />,
    2: <MdChecklist size={25} />,
    3: <MdOutlineHourglassEmpty size={25} />,
    4: <MdCheck size={25} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CheckoutModal = ({ order, set_order, open, onClose, products }) => {
  const [nomcomplete, setNomcomplete] = useState(JSON.parse(localStorage.getItem("client"))?.name_client ?? '');
  const [PhoneNumber, setPhoneNumber] = useState(JSON.parse(localStorage.getItem("client"))?.phone ?? '');
  const [email, set_email] = useState(JSON.parse(localStorage.getItem("client"))?.email ?? '');
  const [Adress, setAdress] = useState(JSON.parse(localStorage.getItem("client"))?.adresse ?? '');
  const [neighborhood, set_neighborhood] = useState(JSON.parse(localStorage.getItem("client"))?.neighborhood_id ?? '');
  const [order_id, set_order_id] = useState(JSON.parse(localStorage.getItem("order"))?.id ?? '');

  const steps = ['Votre Panier', 'Formulaire', 'En Attente', 'Confirmation'];
  const [Note, setNote] = useState('');
  const [step, set_step] = useState(order_id ? 2 : 0);
  const [open_back_drop, set_open_back_drop] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [is_livraison, set_is_livraison] = useState(false);
  const [open_snackbar, set_open_snackbar] = React.useState(false);
  const [snackbar_message, set_snackbar_message] = React.useState('');
  const domRef = React.useRef();
  const is_sm = useMediaQuery(theme.breakpoints.down('sm'));

  const enqueueSnackbar = (m) => {
    set_snackbar_message(m)
    set_open_snackbar(true)
    return new Promise((resolve) => {
      setTimeout(() => {
        set_open_snackbar(false)
        resolve();
      }, 5000);
    });
  };

  const [location, setLocation] = useState(null);

  // useEffect(() => {
  //   const requestGeolocationPermission = async () => {
  //     try {
  //       if ('geolocation' in navigator) {
  //         const permission = await navigator.geolocation.requestPermission();

  //         if (permission === 'granted') {
  //           navigator.geolocation.getCurrentPosition(
  //             (position) => {
  //               const userLocation = {
  //                 latitude: position.coords.latitude,
  //                 longitude: position.coords.longitude,
  //               };
  //               setLocation(userLocation);
  //             },
  //             (error) => {
  //               console.error('Error getting user location:', error);
  //             }
  //           );
  //         } else {
  //           console.error('Geolocation permission denied.');
  //         }
  //       } else {
  //         console.error('Geolocation is not available in this browser.');
  //       }
  //     } catch (error) {
  //       console.error('Error checking geolocation permission:', error);
  //     }
  //   };

  //   requestGeolocationPermission();
  // }, []);

  // const [locationPermission, setLocationPermission] = useState(null);

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
  //       if (permissionStatus.state === 'denied') {
  //         // alert('Please allow location access.');
  //         // window.location.href = "app-settings:location";
  //       } else {
  //         navigator.geolocation.getCurrentPosition(
  //           (position) => {
  //             const userLocation = {
  //               latitude: position.coords.latitude,
  //               longitude: position.coords.longitude,
  //             };
  //             setLocation(userLocation);
  //             // console.log(userLocation);
  //             // console.log('granted');
  //           },
  //           (error) => {
  //             if (error.code === error.PERMISSION_DENIED) {
  //               // console.log('denied');
  //             }
  //           }
  //         );
  //       }
  //     });
  //   } else {
  //     // alert('Geolocation is not supported in your browser.');
  //   }
  // }, [step]);

  useEffect(() => {
    if (open_snackbar) set_open_snackbar(false)
  }, [nomcomplete, PhoneNumber, Adress, neighborhood]);

  const ConfirmOrder = async () => {
    set_open_snackbar(false)
    if (nomcomplete == '') enqueueSnackbar('Merci de saisir votre nom')
    else if (PhoneNumber == '') enqueueSnackbar('Merci de saisir votre phone')
    else if (email == '') enqueueSnackbar('Merci de saisir votre email')
    else if (is_livraison && Adress == '') enqueueSnackbar('Merci de saisir votre adresse')
    else if (is_livraison && neighborhood == '') enqueueSnackbar('Merci de saisir votre quartier')
    else {
      set_open_back_drop(true)
      await axios
        .post(`${process.env.REACT_APP_API_URL}orders_store`, {
          products: Object.values(products)?.map(p => ({ product_id: p?.id, qte: p?.qte })),
          status_id: 10, type: is_livraison ? 10 : 20, name_client: nomcomplete,
          phone: PhoneNumber, adresse: !is_livraison ? null : Adress, email: email,
          notes: Note, neighborhood_id: !is_livraison ? null : neighborhood,
          location: location?.latitude && location?.longitude ? (location?.latitude ?? '') + ',' + (location?.longitude ?? '') : null
        })
        .then((res) => {
          if (!res?.data?.result) enqueueSnackbar(res?.data?.message)
          if (res?.data?.result == true) {
            set_step(2)
            set_order_id(res?.data?.id)
            set_order(o => ({ ...o, id: res?.data?.id }))

            if (is_livraison) localStorage.setItem('client', JSON.stringify({ order_id: res?.data?.id, name_client: nomcomplete, phone: PhoneNumber, adresse: Adress, email: email, neighborhood_id: neighborhood }))
            else localStorage.setItem('client', JSON.stringify({ order_id: res?.data?.id, name_client: nomcomplete, phone: PhoneNumber, email: email }))
          }
        })
        .catch((err) => enqueueSnackbar('Erreur Côte Serveur: La commande non effectuée'))
      set_open_back_drop(false)
    }
  }

  const set_qte = async (id, qte) => {
    await set_order({ ...order, products: order?.products.map((p) => p?.id == id ? { ...p, qte: qte } : p) });
  };

  // Partie sync commande if confirmed or not
  {
    const [progress_seconde, set_progress_seconde] = React.useState(5)
    const interval = React.useRef();

    useEffect(() => {
      if (step == 2) {

        interval.current = setInterval(() => {
          if (moment().format('s') % progress_seconde == 0) {
            // console.log(moment().format('s'), order_id);
            axios
              .get(`${process.env.REACT_APP_API_URL}orders/status/${order_id}`)
              .then(async (res) => {
                try {
                  if (res?.data?.result) {
                    if (res?.data?.data?.status_id == 20) {
                      set_step(3)
                      set_order_id(null)
                      set_order({ products: [], total: 0 })
                    }
                    if (res?.data?.data?.status_id == 30) {
                      set_step(0)
                      set_order_id(null)
                      set_order(o => ({ ...o, id: null }))
                      enqueueSnackbar('Votre commande a été annuler')
                    }
                  }
                } catch (error) {
                  console.log(error)
                }
              })
              .catch(() => console.log('error!'))
          }
        }, 1000)
        return () => {
          clearInterval(interval.current)
        };
      }
    }, [step])
  }

  return (
    <Dialog rootRef={domRef} fullScreen={fullScreen} maxWidth='md' open={open} TransitionComponent={Transition} onClose={() => { onClose(); set_step(s => order_id ? s : 0) }}
      PaperProps={{
        style: { borderRadius: 20 }
      }}
    >

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open_snackbar}
        // onClose={() => set_open_snackbar(false)}
        TransitionComponent={(props) => <Slide {...props} direction="left" />}
        message={snackbar_message}
      />

      <AppBar sx={{ position: 'relative', mb: 3 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => { onClose(); set_step(s => order_id ? s : 0) }} aria-label="close">
            <MdClose />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{steps[step] ?? ''}</Typography>
        </Toolbar>
      </AppBar>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open_back_drop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack sx={{ width: '100%', pb: 4 }} spacing={4}>
        <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>

      {step === 0 &&
        <Grid container sx={{ height: '100%' }}>

          <Grid item xs={12} sx={{
            maxHeight: is_sm ? '51vh' : '35vh',
            overflow: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              background: "#f1f1f1",
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555'
            }
          }}>

            <Table size="small" sx={{ backgroundColor: "primary.main" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Total</TableCell>
                </TableRow>
              </TableHead>
            </Table>

            <Table size="small" sx={{ backgroundColor: "primary.main" }}>
              <TableBody>
                {products && Object.values(products).map((row) =>
                  <TableRow key={row?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell sx={{ display: 'flex', alignItems: 'center', whiteSpace: "nowrap1" }}>
                      <Avatar src={process.env.REACT_APP_URL + "storage/" + row?.image} />
                      <Box sx={{ ml: 1 }}>
                        <Typography fontSize={15} color='secondary'>{row?.category?.name}</Typography>
                        <Typography fontSize={15}>{row?.name}</Typography>
                        <Typography fontSize={10}>{n_format(row?.price, 0)} {JSON.parse(localStorage.getItem("configs"))?.currency ?? 'DH'}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ display: 'inline-flex' }}>
                        <IconButton onClick={() => set_qte(row?.id, row?.qte - 1)}><MdRemove /></IconButton>
                        <IconButton sx={{ textAlign: 'center', px: 1, fontSize: 16 }} >{row?.qte}</IconButton>
                        <IconButton sx={{ textAlign: 'center' }} onClick={() => set_qte(row?.id, row?.qte + 1)}><MdAdd /></IconButton>
                      </Box>
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>{n_format(row?.price * row?.qte, 0)} {JSON.parse(localStorage.getItem("configs"))?.currency ?? 'DH'}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

          </Grid>

          <Grid item xs={12}>
            <Table size="small" sx={{ color: "secondary.light" }}>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={3} align='right' sx={{ fontSize: 22, py: 1 }}>
                    <Box sx={{ fontSize: 22, whiteSpace: "nowrap" }}>Total : {n_format(order?.total, 2)} {JSON.parse(localStorage.getItem("configs"))?.currency ?? 'DH'}</Box>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>

            {
              is_livraison && !((order?.total ?? 0) >= 250) &&
              <Grid item xs={12} sx={{ textAlign: 'center', py: 2, px: 1 }}>
                <Typography color='secondary.main'>Ajoutez des éléments jusqu'à atteindre un minimum de 250.00 Dhs (pour la livraison)</Typography>
              </Grid>
            }

            <Grid container xs={12} sm={12} md={12} lg={12} sx={{ px: 2, py: 3, width: '100%' }} >
              <Typography sx={{ fontSize: 25, borderRadius: 10, p: 1.5, mx: '2.5%', width: '45%', textAlign: 'center', bgcolor: 'secondary.main' }}
                onClick={() => {
                  set_is_livraison(true)
                  if ((order?.total ?? 0) >= 250) set_step(1)
                }}
              >
                Livraison
              </Typography>
              <Typography sx={{ fontSize: 25, borderRadius: 10, p: 1.5, mx: '2.5%', width: '45%', textAlign: 'center', bgcolor: 'secondary.main' }}
                onClick={() => {
                  set_is_livraison(false)
                  set_step(1)
                }}
              >
                Emporter
              </Typography>
            </Grid>
          </Grid>

        </Grid >
      }

      {step === 1 &&
        <Box sx={{ px: 5, py: 2, pt: 2 }}>
          <Typography align='center' variant="h6" gutterBottom sx={{ pb: 2 }}>Remplir le formulaire</Typography>

          <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <TextField required label="Nom Complet" color="secondary" variant="standard"
              value={nomcomplete}
              onChange={(e) => setNomcomplete(e.target.value)}
            />

            {is_livraison &&
              <Autocomplete
                size="small"
                options={[
                  { key: 1, value: 'Ain Diab' },
                  { key: 2, value: 'Anfa Finance City' },
                  { key: 3, value: 'Anfa Superieur' },
                  { key: 4, value: 'Bourgogne' },
                  { key: 5, value: 'Californie' },
                  { key: 6, value: 'Centre Ville' },
                  { key: 7, value: 'CIL' },
                  { key: 8, value: 'Deux Mars' },
                  { key: 9, value: 'Ferme Bretonne' },
                  { key: 10, value: 'Gauthier' },
                  { key: 11, value: 'Maarif' },
                  { key: 12, value: 'Maarif Extension' },
                  { key: 13, value: 'Mers Sultan' },
                  { key: 14, value: 'Oasis' },
                  { key: 15, value: 'neighborhood Californie' },
                  { key: 16, value: 'Route d\'azzemoure (avant ecole goeorge washington)' },
                  { key: 17, value: 'Sidi Maarouf' },
                  { key: 18, value: 'Val Fleuri' },
                ]}
                getOptionLabel={(o) => o?.value}
                onChange={(event, newValue) => {
                  set_neighborhood(newValue?.key)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Quartier *"
                    color="secondary"
                  />
                )}
              />
            }

            {is_livraison &&
              <TextField required label="Adresse Complète" color="secondary" variant="standard"
                value={Adress}
                onChange={(e) => setAdress(e.target.value)}
              />
            }

            <TextField required label="Numéro Téléphone" color="secondary" autoComplete="false" variant="standard"
              value={PhoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <TextField required label="Email" color="secondary" autoComplete="false" variant="standard"
              value={email}
              onChange={(e) => set_email(e.target.value)}
            />

            <TextField label="Notes" color="secondary" autoComplete="false" variant="standard"
              value={Note}
              onChange={(e) => setNote(e.target.value)}
            />

            <Box sx={{ display: "flex", justifyContent: "center", gap: 15, mt: 2, mb: 2 }}>
              <Button variant="contained" onClick={() => set_step(0)}>Annuler</Button>
              <Button variant="contained" endIcon={<MdLabelImportant />} onClick={ConfirmOrder}>Confirmer</Button>
            </Box>
          </Box>

        </Box>
      }

      {step === 2 &&
        <Paper sx={{ py: 4, px: 2, textAlign: 'center' }}>
          <CircularProgress size={60} color="secondary" sx={{ mb: 3 }} />
          <Typography>
            Pour valider votre commande vous recevrez un appel du Nori<br></br>
            suivis d’un e-mail de confirmation<br></br>
            Merci de patienter
          </Typography>
        </Paper>
      }

      {step === 3 &&
        <Paper sx={{ py: 4, px: 2, textAlign: 'center' }}>
          <IconButton sx={{ mb: 3, border: 4, borderColor: 'green' }}>
            <MdDone color="green" size={60} />
          </IconButton>
          <Typography>Votre commande a été confirmée</Typography>
        </Paper>
      }
    </Dialog >
  )

};

export default CheckoutModal;
